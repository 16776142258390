import PropTypes from "prop-types"
import { Alert, Grid } from "@mui/material"
import { useEffect, useState } from "react"
import { set, useFormContext } from "react-hook-form"
import Dropdown from "components/fields/Dropdown"
import { assignCampaign } from "services/assignCampaign"
import Creneaux from "components/Creneaux"
import { useForms } from "hooks/useForm"
import { usePrevious } from "hooks/usePrevious"
import { campaignStatus, formatEvent } from "services"

const Campaigns = ({ contact = true, division = '', eda = false, last_form, limit = 20, school, setAcademicLevels, text, textAvailability, type }) => {

  const { control, setValue, watch } = useFormContext()
  const { componentFetched, recordTypeId, salesforce, setComponentStates, setOpenSnackbar, setSnackMessage } = useForms()
  const academicLevel = watch('lead.AcademicLevel__c')
  const previousAcademicLevel = usePrevious(academicLevel)
  const campus = watch('lead.Campus__c')
  const previousCampus = usePrevious(campus)
  const campaignNational = watch('campaignNational')
  const campaignId = watch('campaignId')

  const [campaigns, setCampaigns] = useState([])
  const [optionsCampaigns, setOptionsCampaigns] = useState([])
  const [campaignFetched, setCampaignFetched] = useState(false)
  const [academicLevelsLength, setAcademicLevelsLength] = useState(0)

  useEffect(() => {
    setComponentStates((prevState) => [
      ...prevState,
      { name: 'campaigns', added: true, fetched: false },
    ])
  }, [setComponentStates])

  useEffect(() => {
    if (setAcademicLevels !== undefined && (campaignId === undefined || campaignId === '')) {
      const firstValuesByCampus = campaigns.reduce((result, item) => {
        const campus = item.Campus__c
        result[campus] = result[campus] || []
        if (result[campus].length < limit) {
          result[campus].push({
            Id: item.Id,
            EventFor2nde__c: item.EventFor2nde__c,
            EventForPremiere__c: item.EventForPremiere__c,
            EventForTerminale__c: item.EventForTerminale__c
          })
        }
        return result
      }, {})

      // On retire le [campus]
      const campaignsAcademics = Object.values(firstValuesByCampus).flat()

      // On doit savoir si les campagnes sont dispos selon tel niveau académique
      const academics = [
        { label: 'Seconde', value: 'EventFor2nde__c' },
        { label: 'Première', value: 'EventForPremiere__c' },
        { label: 'Terminale (BAC)', value: 'EventForTerminale__c' }
      ].filter(level => campaignsAcademics.some(campaign => campaign[level.value]))

      const defaultAcademics = [
        { label: 'Seconde', value: 'Seconde' },
        { label: 'Première', value: 'Première' },
        { label: 'Terminale (BAC)', value: 'Terminale (BAC)' },
      ]

      const finalAcademics = academics.length
      ? academics.map(level => ({
        label: level.label,
        value: level.label
      }))
      : defaultAcademics

      // On envoi enfin à la fonction pour l'envoyer à l'enfant
      if (finalAcademics.length > 0) {
        setAcademicLevels(finalAcademics)
      } else {
        // setAcademicLevels(undefined)
      }
      setAcademicLevelsLength(finalAcademics.length)
    }
    if (campaignId !== undefined && campaignId !== '' && campaigns.length > 0 && setAcademicLevels !== undefined) {
      console.log('on a mis un id de campagneId')
      const campaignDetail = campaigns.find(el => el.Id === campaignId)
      if (campaignDetail !== undefined) {
        const academics = [
          { label: 'Seconde', value: 'EventFor2nde__c' },
          { label: 'Première', value: 'EventForPremiere__c' },
          { label: 'Terminale (BAC)', value: 'EventForTerminale__c' }
        ].filter(level => campaignDetail[level.value])
        const finalAcademics = academics.map(level => ({
          label: level.label,
          value: level.label
        }))
        setAcademicLevels(finalAcademics)
      } else {
        // setAcademicLevels(undefined)
      }
    }
  }, [campaigns, campaignId])

  useEffect(() => {
    if (recordTypeId !== '') {
      const getData = async () => {
        const campaigns = await salesforce.getCampaigns(division, school, type)

        return { campaigns }
      }

      getData().then((value) => {
        const { campaigns } = value

        setCampaigns(campaigns)
        // On vérifie si il existe pas un paramètre CampaignId, si oui on assigne les bonnes valeurs sur le formulaire
        assignCampaign(campaigns, setValue)
        componentFetched('campaigns')
        setCampaignFetched(true)
      }).catch((error) => {
        setOpenSnackbar(true)
        console.log('erreur', error)
        setSnackMessage('Une erreur est survenue lors du chargement du formulaire')
      })
    }
  }, [recordTypeId])

  useEffect(() => {
    if (salesforce !== null && campaignFetched) {
      if (!campaignNational && ((academicLevelsLength > 1 && setAcademicLevels !== null) || previousCampus !== campus || (setAcademicLevels && previousAcademicLevel !== academicLevel))) {
        setValue('campaignId', '')
      }
      if (campus !== '' && !campaignNational) {
        const academicLevelMapping = {
          "Seconde": "EventFor2nde__c",
          "Première": "EventForPremiere__c",
          "Terminale (BAC)": "EventForTerminale__c"
        }
        const academicLevelProp = academicLevelMapping[academicLevel]
        const currentCampaigns = campaigns.filter(el => (el.Campus__c === campus || (el.Campus__c === 'National' && (el.Detail__c === 'Webinar' || el.Detail__c === 'Autre'))) && (!setAcademicLevels || el[academicLevelProp] === true || academicLevelProp === undefined)).slice(0, limit)
        setFinalCampaigns(currentCampaigns)
      } else if (campaignNational) {
        const currentCampaigns = campaigns.filter(el => el.Campus__c === 'National').slice(0, limit)
        setFinalCampaigns(currentCampaigns)
      }
    }
  }, [academicLevel, campaignNational, campaignFetched, campaigns, campus, salesforce])

  useEffect(() => {
    if (campaignId !== '' && campaignId !== 'unavailable') {
      const finalCampaigns = formatEvent(campaigns, contact, eda)
      const campaign = finalCampaigns.find(el => el.value === campaignId)
      let eventDateAndDuration = ''
      if (campaign !== undefined) {
        eventDateAndDuration = campaign.dateDuration
      }
      setValue('lead.EventDateAndDuration__c', eventDateAndDuration)
      const campaignSalesforce = campaigns.find(el => el.Id === campaignId)
      if (!eda) {
        setValue('lead.Labelcampaign__c', campaignSalesforce.Label__c)
      }
      let lastForm = ''
      if (last_form !== undefined) {
        lastForm = last_form
      } else if (campaignSalesforce !== undefined) {
        lastForm = campaignSalesforce.Detail__c
      }
      if (campaignSalesforce.IsOnline__c){
        lastForm += ' distanciel'
      }
      if (!eda) {
        setValue('lead.LastForm__c', lastForm)
      }

      const status = campaignStatus.find(el => el.eda === eda && el.online === campaignSalesforce?.IsOnline__c)?.name
      const campaignCustomText = campaignSalesforce?.CustomText__c

      setValue('lead.CampaignCustomText__c', campaignCustomText)
      setValue('status', status)
      setValue('slotId', '')
    }
  }, [campaignId])

  const setFinalCampaigns = (currentCampaigns) => {
    const finalCampaigns = formatEvent(currentCampaigns, contact, eda)
    setOptionsCampaigns(finalCampaigns)
    if (!campaignNational) {
      assignCampaign(currentCampaigns, setValue)
    }
    if (currentCampaigns.length === 0) {
      setValue('campaignId', 'unavailable')
    }
    // setValue('slotId', '')
  }

  return (
    <>
      {((optionsCampaigns.length > 0 && !contact) || (optionsCampaigns.length > 1 && contact) || campus === '') && (
        <Grid item xs={12}>
          <Dropdown control={control} disabled={campus === '' && !campaignNational} label={`Je souhaite participer ${text}`} name="campaignId" options={optionsCampaigns} required />
        </Grid>
      )}
      <Creneaux campaigns={campaigns} />
      {!contact && optionsCampaigns.length === 0 && campus !== '' && (
        <Grid item xs={12}>
          <Alert color="info">
            Il n'y a plus de dates {textAvailability} à {campus}.
          </Alert>
        </Grid>
      )}
    </>
  )
}

Campaigns.propTypes = {
  contact: PropTypes.bool,
  division: PropTypes.string,
  eda: PropTypes.bool,
  last_form: PropTypes.string,
  limit: PropTypes.number,
  school: PropTypes.string,
  setAcademicLevels: PropTypes.func,
  text: PropTypes.string,
  textAvailability: PropTypes.string,
  type: PropTypes.string
}

export default Campaigns
