import PropTypes from "prop-types"
import { CssBaseline,  } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import { default as AfterSchool } from "./AfterSchool/Form"
import { default as Atelier } from "./Atelier/Form"
import { default as Documentation } from "./Documentation/Form"
import { default as JourneesImmersion } from "./JourneesImmersion/Form"
import { default as JourneesPortesOuvertes } from "./JourneesPortesOuvertes/Form"
import { default as Precandidature } from "./Candidature/Form"
import { default as Webinar } from "./Webinar/Form"
import { customTheme } from "./Theme/index"
import { FormContextProvider } from "hooks/useForm"
import Forms from "../Forms"

const AfterSchoolIAInstitut = ({ elevation, primarycolor, sx }) => {
  const theme = customTheme(primarycolor)
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms elevation={elevation} sx={sx}>
          <CssBaseline />
          <AfterSchool />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const AtelierIAInstitut = ({ elevation, primarycolor, sx }) => {
  const theme = customTheme(primarycolor)
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms elevation={elevation} sx={sx}>
          <CssBaseline />
          <Atelier />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const DocumentationIAInstitut = ({ elevation, primarycolor, sx }) => {
  const theme = customTheme(primarycolor)
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms elevation={elevation} sx={sx}>
          <CssBaseline />
          <Documentation />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const JourneesImmersionIAInstitut = ({ elevation, primarycolor, sx }) => {
  const theme = customTheme(primarycolor)
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms elevation={elevation} sx={sx}>
          <CssBaseline />
          <JourneesImmersion />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const JourneesPortesOuvertesIAInstitut = ({ elevation, primarycolor, sx }) => {
  const theme = customTheme(primarycolor)
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms elevation={elevation} sx={sx}>
          <CssBaseline />
          <JourneesPortesOuvertes />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const PrecandidatureIAInstitut = ({ elevation, primarycolor, sx }) => {
  const theme = customTheme(primarycolor)
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms elevation={elevation} sx={sx}>
          <CssBaseline />
          <Precandidature />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const WebinarIAInstitut = ({ elevation, primarycolor, sx }) => {
  const theme = customTheme(primarycolor)
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms elevation={elevation} sx={sx}>
          <CssBaseline />
          <Webinar />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

AtelierIAInstitut.propTypes = {
  elevation: PropTypes.number,
  primarycolor: PropTypes.string,
  sx: PropTypes.object
}

PrecandidatureIAInstitut.propTypes = {
  elevation: PropTypes.number,
  primarycolor: PropTypes.string,
  sx: PropTypes.object
}

export { AfterSchoolIAInstitut, AtelierIAInstitut, DocumentationIAInstitut, JourneesImmersionIAInstitut, JourneesPortesOuvertesIAInstitut, PrecandidatureIAInstitut, WebinarIAInstitut }
