import { AppBar, Button, Menu, MenuItem, Toolbar } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import { Link } from "react-router-dom"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(() => ({
  link: {
    color: 'inherit',
    textDecoration: 'none'
  }
}))

const MenuForm = () => {


  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState({});

  const handleClick = (event, id) => {
    setAnchorEl({...anchorEl, [id]: event.currentTarget});
  };

  const handleClose = () => {
    setAnchorEl({});
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
        <Button color="inherit" onClick={(e) => handleClick(e, "menuadvance")}>
            Advance
          </Button>
          <Menu
            id="menuadvance"
            anchorEl={anchorEl.menuadvance}
            keepMounted
            open={Boolean(anchorEl.menuadvance)}
            onClose={handleClose}
          >
            <Link className={classes.link} to="/advance-documentation">
              <MenuItem onClick={handleClose}>
                Documentation
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/advance-candidature">
              <MenuItem onClick={handleClose}>
                Candidature EPITA Cycle Bachelor
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/advance-candidature-epita">
              <MenuItem onClick={handleClose}>
                Candidature EPITA
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/advance-candidature-esme">
              <MenuItem onClick={handleClose}>
                Candidature ESME
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/advance-candidature-ipsa">
              <MenuItem onClick={handleClose}>
                Candidature IPSA
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/advance-candidature-supbiotech">
              <MenuItem onClick={handleClose}>
                Candidature SupBiotech
              </MenuItem>
            </Link>
          </Menu>
          <Button color="inherit" onClick={(e) => handleClick(e, "menueartsup")}>
            e-artsup
          </Button>
          <Menu
            id="menueartsup"
            anchorEl={anchorEl.menueartsup}
            keepMounted
            open={Boolean(anchorEl.menueartsup)}
            onClose={handleClose}
          >
            <Link className={classes.link} to="/eartsup-documentation">
              <MenuItem onClick={handleClose}>
                Documentation
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/eartsup-atelier">
              <MenuItem onClick={handleClose}>
                Atelier
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/eartsup-conference">
              <MenuItem onClick={handleClose}>
                Conférence
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/eartsup-ji">
              <MenuItem onClick={handleClose}>
                Journée Immersion
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/eartsup-jpo">
              <MenuItem onClick={handleClose}>
                JPO
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/eartsup-livreblanc-etudiant">
              <MenuItem onClick={handleClose}>
                Livre blanc (étudiant)
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/eartsup-livreblanc-parent">
              <MenuItem onClick={handleClose}>
                Livre blanc (parent)
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/eartsup-contact">
              <MenuItem onClick={handleClose}>
                Contact
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/eartsup-precandidature">
              <MenuItem onClick={handleClose}>
                Pré-candidature
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/eartsup-webinar">
              <MenuItem onClick={handleClose}>
                Webinar
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/eartsup-event-generique">
              <MenuItem onClick={handleClose}>
                Evenement générique
              </MenuItem>
            </Link>
          </Menu>
          <Button color="inherit" onClick={(e) => handleClick(e, "menuepita")}>
            EPITA
          </Button>
          <Menu
            id="menuepita"
            anchorEl={anchorEl.menuepita}
            keepMounted
            open={Boolean(anchorEl.menuepita)}
            onClose={handleClose}
          >
            <Link className={classes.link} to="/epita-documentation">
              <MenuItem onClick={handleClose}>
                Documentation
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/epita-jpo">
              <MenuItem onClick={handleClose}>
                JPO
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/epita-jdmi">
              <MenuItem onClick={handleClose}>
                JDMI
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/epita-webinar">
              <MenuItem onClick={handleClose}>
                Live
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/epita-event-generique">
              <MenuItem onClick={handleClose}>
                Evenement générique
              </MenuItem>
            </Link>
          </Menu>
          <Button color="inherit" onClick={(e) => handleClick(e, "menuepitechdigital")}>
            Epitech Digital
          </Button>
          <Menu
            id="menuepitechdigital"
            anchorEl={anchorEl.menuepitechdigital}
            keepMounted
            open={Boolean(anchorEl.menuepitechdigital)}
            onClose={handleClose}
          >
            <Link className={classes.link} to="/epitech-digital-documentation">
              <MenuItem onClick={handleClose}>
                Documentation
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/epitech-digital-digiclub">
              <MenuItem onClick={handleClose}>
                Digiclub
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/epitech-digital-jpo">
              <MenuItem onClick={handleClose}>
                JPO
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/epitech-digital-contact">
              <MenuItem onClick={handleClose}>
                Contact
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/epitech-digital-contact-rd">
              <MenuItem onClick={handleClose}>
                Contact RD
              </MenuItem>
            </Link>
          </Menu>
          <Button color="inherit" onClick={(e) => handleClick(e, "menuesme")}>
            ESME
          </Button>
          <Menu
            id="menuesme"
            anchorEl={anchorEl.menuesme}
            keepMounted
            open={Boolean(anchorEl.menuesme)}
            onClose={handleClose}
          >
            <Link className={classes.link} to="/esme-documentation">
              <MenuItem onClick={handleClose}>
                Documentation
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/esme-jpo">
              <MenuItem onClick={handleClose}>
                JPO
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/esme-jdmi">
              <MenuItem onClick={handleClose}>
                JDMI
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/esme-rdv-orientation">
              <MenuItem onClick={handleClose}>
                RDV Orientation
              </MenuItem>
            </Link>
          </Menu>
          <Button color="inherit" onClick={(e) => handleClick(e, "menuiainstitut")}>
            IA Institut
          </Button>
          <Menu
            id="menuiainstitut"
            anchorEl={anchorEl.menuiainstitut}
            keepMounted
            open={Boolean(anchorEl.menuiainstitut)}
            onClose={handleClose}
          >
            <Link className={classes.link} to="/iainstitut-documentation">
              <MenuItem onClick={handleClose}>
                Documentation
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/iainstitut-jpo">
              <MenuItem onClick={handleClose}>
                JPO
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/iainstitut-atelier">
              <MenuItem onClick={handleClose}>
                Atelier
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/iainstitut-after-school">
              <MenuItem onClick={handleClose}>
                After School
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/iainstitut-journee-immersion">
              <MenuItem onClick={handleClose}>
                Journée immersion
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/iainstitut-webinar">
              <MenuItem onClick={handleClose}>
                Live
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/iainstitut-precandidature">
              <MenuItem onClick={handleClose}>
                Pré-candidature
              </MenuItem>
            </Link>
          </Menu>
          <Button color="inherit" onClick={(e) => handleClick(e, "menuipsa")}>
            IPSA
          </Button>
          <Menu
            id="menuipsa"
            anchorEl={anchorEl.menuipsa}
            keepMounted
            open={Boolean(anchorEl.menuipsa)}
            onClose={handleClose}
          >
            <Link className={classes.link} to="/ipsa-documentation">
              <MenuItem onClick={handleClose}>
                Documentation
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/ipsa-jpo">
              <MenuItem onClick={handleClose}>
                JPO
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/ipsa-jdmi">
              <MenuItem onClick={handleClose}>
                JDMI
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/ipsa-bachelor-day">
              <MenuItem onClick={handleClose}>
                Bachelor Day
              </MenuItem>
            </Link>
          </Menu>
          <Button color="inherit" onClick={(e) => handleClick(e, "menuisg")}>
            ISG
          </Button>
          <Menu
            id="menuisg"
            anchorEl={anchorEl.menuisg}
            keepMounted
            open={Boolean(anchorEl.menuisg)}
            onClose={handleClose}
          >
            <Link className={classes.link} to="/isg-documentation-msc">
              <MenuItem onClick={handleClose}>
                Documentation Msc
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/isg-jpo">
              <MenuItem onClick={handleClose}>
                JPO
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/isg-contact">
              <MenuItem onClick={handleClose}>
                Contact
              </MenuItem>
            </Link>
          </Menu>
          <Button color="inherit" onClick={(e) => handleClick(e, "menuisgluxury")}>
            ISG Luxury
          </Button>
          <Menu
            id="menuisgluxury"
            anchorEl={anchorEl.menuisgluxury}
            keepMounted
            open={Boolean(anchorEl.menuisgluxury)}
            onClose={handleClose}
          >
            <Link className={classes.link} to="/isg-luxury-jpo">
              <MenuItem onClick={handleClose}>
                JPO
              </MenuItem>
            </Link>
          </Menu>
          <Button color="inherit" onClick={(e) => handleClick(e, "menuiseg")}>
            ISEG
          </Button>
          <Menu
            id="menuiseg"
            anchorEl={anchorEl.menuiseg}
            keepMounted
            open={Boolean(anchorEl.menuiseg)}
            onClose={handleClose}
          >
            <Link className={classes.link} to="/iseg-documentation">
              <MenuItem onClick={handleClose}>
                Documentation
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/iseg-jpo">
              <MenuItem onClick={handleClose}>
                Journées Portes Ouvertes
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/iseg-journee-immersion">
              <MenuItem onClick={handleClose}>
                Journée d'immertion
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/iseg-printemps-orientation">
              <MenuItem onClick={handleClose}>
                Printemps de l'orientation
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/iseg-soiree-speciale">
              <MenuItem onClick={handleClose}>
                Soirée Spéciale
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/iseg-contact">
              <MenuItem onClick={handleClose}>
                Contact
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/iseg-guide-metiers">
              <MenuItem onClick={handleClose}>
                Guide des métiers
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/iseg-comon-demain">
              <MenuItem onClick={handleClose}>
                Com'on Demain
              </MenuItem>
            </Link>
          </Menu>
          <Button color="inherit" onClick={(e) => handleClick(e, "menusupbiotech")}>
            SupBiotech
          </Button>
          <Menu
            id="menusupbiotech"
            anchorEl={anchorEl.menusupbiotech}
            keepMounted
            open={Boolean(anchorEl.menusupbiotech)}
            onClose={handleClose}
          >
            <Link className={classes.link} to="/supbiotech-documentation">
              <MenuItem onClick={handleClose}>
                Documentation
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/supbiotech-jpo">
              <MenuItem onClick={handleClose}>
                Journées Portes Ouvertes
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/supbiotech-biotech-days">
              <MenuItem onClick={handleClose}>
                Biotech Days
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/supbiotech-time-travel">
              <MenuItem onClick={handleClose}>
                Time Travel
              </MenuItem>
            </Link>
          </Menu>
          <Button color="inherit" onClick={(e) => handleClick(e, "menusupinfo")}>
            Supinfo
          </Button>
          <Menu
            id="menusupinfo"
            anchorEl={anchorEl.menusupinfo}
            keepMounted
            open={Boolean(anchorEl.menusupinfo)}
            onClose={handleClose}
          >
            <Link className={classes.link} to="/supinfo-documentation">
              <MenuItem onClick={handleClose}>
                Documentation
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/supinfo-jpo">
              <MenuItem onClick={handleClose}>
                Journées Portes Ouvertes
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/supinfo-ji">
              <MenuItem onClick={handleClose}>
                Journées Immersive
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/supinfo-atelier">
              <MenuItem onClick={handleClose}>
                Atelier
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/supinfo-conference">
              <MenuItem onClick={handleClose}>
                Conférence
              </MenuItem>
            </Link>
          </Menu>
          <Button color="inherit" component={Link} to="/landing-page-form">
            Landing Page Form
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default MenuForm
