const NIVEAUX = {
  TROISIEME: {
    label: "Troisième",
    value: "Troisième"
  },
  SECONDE: {
    label: "Seconde",
    value: "Seconde"
  },
  PREMIERE: {
    label: "Première",
    value: "Première"
  },
  TERMINALE: {
    label: "Terminale (BAC)",
    value: "Terminale (BAC)"
  },
  BAC: {
    label: "BAC",
    value: "BAC"
  },
  BACMATURITE: {
    label: "BAC/Maturité",
    value: "BAC"
  },
  BAC1: {
    label: "Bac +1",
    value: "Bac +1"
  },
  BAC2: {
    label: "Bac +2",
    value: "Bac +2"
  },
  BAC3: {
    label: "Bac +3",
    value: "Bac +3"
  },
  BAC4: {
    label: "Bac +4",
    value: "Bac +4"
  },
  BAC5: {
    label: "Bac +5",
    value: "Bac +5"
  },
  AUTRE: {
    label: "Autre",
    value: "Autre"
  }
}

// Fonction pour récupérer les niveaux académiques spécifiques à une école
const getAcademicLevels = (school, form) => {
  switch (school) {
    case "e-artsup":
      return [NIVEAUX.TERMINALE, NIVEAUX.BAC1, NIVEAUX.BAC2, NIVEAUX.BAC3, NIVEAUX.BAC4, NIVEAUX.BAC5]
    case "EPITA":
      return []
    case "EPITA IA Institut":
      if (form === "candidature") {
        return [NIVEAUX.TERMINALE, NIVEAUX.BAC, NIVEAUX.BAC1, NIVEAUX.BAC2, NIVEAUX.BAC3, NIVEAUX.BAC4, NIVEAUX.BAC5]
      } else if (form === "atelier") {
        return [NIVEAUX.SECONDE, NIVEAUX.PREMIERE, NIVEAUX.TERMINALE]
      } else if (form === "after school" || form === "JDMI") {
        return [NIVEAUX.TERMINALE]
      } else {
        return [NIVEAUX.SECONDE, NIVEAUX.PREMIERE, NIVEAUX.TERMINALE, NIVEAUX.BAC, NIVEAUX.BAC1, NIVEAUX.BAC2, NIVEAUX.BAC3, NIVEAUX.BAC4, NIVEAUX.BAC5]
      }
    case "Epitech Digital":
      return [NIVEAUX.SECONDE, NIVEAUX.PREMIERE, NIVEAUX.TERMINALE, NIVEAUX.BAC1, NIVEAUX.BAC2, NIVEAUX.BAC3, NIVEAUX.BAC4, NIVEAUX.BAC5, NIVEAUX.AUTRE]
    case "ESME":
      return []
    case "IPSA":
      return []
    case "ISG France":
      return []
    case "ISG Luxury":
      return [NIVEAUX.TROISIEME, NIVEAUX.SECONDE, NIVEAUX.PREMIERE, NIVEAUX.TERMINALE, NIVEAUX.BACMATURITE, NIVEAUX.BAC1, NIVEAUX.BAC2, NIVEAUX.BAC3, NIVEAUX.BAC4, NIVEAUX.BAC5, NIVEAUX.AUTRE]
    case "ISEG MCS":
      return []
    case "SUPINFO":
      return [NIVEAUX.SECONDE, NIVEAUX.PREMIERE, NIVEAUX.TERMINALE, NIVEAUX.BAC1, NIVEAUX.BAC2, NIVEAUX.BAC3, NIVEAUX.BAC4, NIVEAUX.BAC5, NIVEAUX.AUTRE]
    default:
      return []
  }
}

export { getAcademicLevels }
